<template>
  <div :style="clickableClass" @click="onClick">
    <v-card v-if="reservation" class="quote-box" flat :style="computedClass">
      <v-layout row wrap no-gutters align="stretch">
        <v-flex cols="auto">
          <v-img
            v-if="stacked && photoSrc"
            :src="photoSrc"
            :width="imgWidth"
            aspect-ratio="2.44"
            :style="imageClass"
          />
          <img
            v-else-if="photoSrc"
            :src="photoSrc"
            :width="imgWidth"
            style="object-fit: cover"
            height="100%"
            :style="imageClass"
          />
          <v-img
            v-if="stacked && !photoSrc"
            :src="require('../../assets/images/charterup/placeholder_bus.jpg')"
            :width="imgWidth"
            height="100%"
            style="object-fit: cover"
            aspect-ratio="2.44"
            :style="imageClass"
          />
          <img
            v-else-if="!photoSrc"
            :src="require('../../assets/images/charterup/placeholder_bus.jpg')"
            :width="imgWidth"
            style="object-fit: cover"
            height="100%"
            :style="imageClass"
          />
        </v-flex>

        <v-flex
          align-self="center"
          :style="stacked ? '' : 'margin: 12px 24px 12px 6px;'"
        >
          <v-layout
            row
            align="stretch"
            :justify="stacked ? 'space-around' : 'center'"
            dense
          >
            <v-flex :style="stacked ? { 'margin-top': '-25px' } : {}">
              <v-flex align-self="center">
                <v-chip label pill style="height: 20px; margin-bottom: 6px">
                  <h5>Your Trip</h5>
                </v-chip>
                <h2 style="font-size: 16px">
                  {{ reservation.company.companyDBAName }}
                </h2>
                <v-layout row class="reviews">
                  <span v-if="reservation.company.rating">
                    <v-rating
                      v-model="reservation.company.rating"
                      color="star"
                      style="margin-top: -1px"
                      half-increments
                      readonly
                      dense
                      size="20"
                    />
                  </span>
                  <span
                    v-if="
                      reservation.company.rating &&
                      reservation.company.rating !== 0
                    "
                  >
                    {{ reservation.company.rating }}
                  </span>
                  <span
                    v-if="
                      reservation.company.reviewCount &&
                      reservation.company.reviewCount !== 0
                    "
                  >
                    {{ `, ${reservation.company.reviewCount} reviews` }}
                  </span>
                </v-layout>
                <v-layout row no-gutters style="min-height: 22px">
                  <div
                    v-for="(amenity, i) in reservation.company.amenityDTO"
                    :key="i"
                    style="margin-right: 3px"
                  >
                    <v-icon color="primary" small>
                      {{ amenity.amenityName | convertAmenityKeyToIcon }}
                    </v-icon>
                  </div>
                </v-layout>
              </v-flex>
            </v-flex>
            <v-flex
              :cols="stacked ? 12 : 'auto'"
              align-self="center"
              :style="stacked ? { padding: '0px 16px 12px 16px' } : {}"
            >
              <v-btn
                id="reservation-card-button-driver-info"
                class="primary d-flex center"
                style="margin-bottom: 12px; background-color: blue"
                block
                small
                @click="callDriverDialogIsOpen = true"
              >
                Driver Info
              </v-btn>
              <v-btn
                id="reservation-card-button-live-tracking"
                block
                color="warning"
                class="primary d-flex center"
                style="color: white; background-color: red"
                small
                @click="openNewTrackingWindow"
              >
                • Live Tracking
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
    <CallDriverDialog
      v-model="callDriverDialogIsOpen"
      :assigned-drivers="assignedDrivers"
      @click-support="$emit('click-support')"
    />
  </div>
</template>

<script>
import CallDriverDialog from '@/components/charterup/CallDriverDialog.vue'
import { convertAmenityKeyToIcon } from '@/utils/string'

export default {
  filters: {
    convertAmenityKeyToIcon,
  },
  components: {
    CallDriverDialog,
  },
  props: {
    stacked: {
      type: Boolean,
    },
    imgWidth: {
      type: Number,
      default: 300,
    },
    reservation: {
      type: Object,
      default: () => {},
    },
    featured: {
      type: Boolean,
    },
    button: {
      type: Boolean,
    },
    to: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      callDriverDialogIsOpen: false,
    }
  },
  computed: {
    computedClass() {
      const compClass = this.featured
        ? {
            outline: 'none',
            'border-color': '#9ecaed',
            'box-shadow': '0 0 12px #9ecaed',
          }
        : {}
      if (this.stacked) {
        compClass.width = `${this.imgWidth}px`
      }
      return compClass
    },
    assignedDrivers() {
      return this.reservation.assignedDrivers
    },
    photoSrc() {
      return this.reservation?.company?.photos?.[0]?.imagePath
    },
    imageClass() {
      const _class = this.stacked
        ? {
            'border-radius': '4px 4px 0px 0px',
          }
        : {
            'border-radius': '4px 0px 0px 4px',
          }
      _class.margin = '0px'
      _class['z-index'] = '0'
      return _class
    },
    clickableClass() {
      return this.to
        ? {
            cursor: 'pointer',
          }
        : {}
    },
  },
  methods: {
    onClick() {
      if (this.to) this.$router.replace(this.to)
    },
    openNewTrackingWindow() {
      const path = {
        name: 'reservation-live-map',
        params: { id: this.reservation.reservationId },
      }
      const routeData = this.$router.resolve(path)
      window.open(routeData.href, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/charterup/design';

.quote-box {
  z-index: 1;
  background-color: transparent;
  border-color: $border-gray;
  border-style: solid;
  border-width: 1px;

  .totals {
    .total {
      font-size: 24px;

      &.bidding {
        margin-top: 5px;
      }

      .bidding {
        font-size: 18px;
      }
    }

    .prev-total {
      font-size: 14px;
    }
  }

  .featured {
    color: $featured;
  }

  .reviews {
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: gray;
  }

  .alert {
    font-size: 12px;
    color: $danger;
  }
}

.preferred-chip {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10;
  overflow: hidden;
  color: $white;
  background-color: $primary;
}

.preferred-text {
  margin-top: 1px;
  color: $white;
}
</style>
