<template>
  <v-container>
    <v-layout row wrap justify="center" no-gutters>
      <v-layout
        row
        wrap
        style="align-items: flex-start"
        :style="{ maxWidth }"
        justify="center"
        no-gutters
      >
        <v-flex
          xs12
          :class="`md${leftColumn}`"
          order="2"
          :style="$cr.breakpoint.mdAndUp ? '' : 'margin-top:24px;'"
        >
          <slot name="left-column" />
        </v-flex>
        <v-flex
          xs12
          :class="`md${rightColumn}`"
          :order="$cr.breakpoint.mdAndUp ? 3 : 1"
          style="align-items: flex-start"
          :style="$cr.breakpoint.mdAndUp ? { paddingLeft: '62px' } : {}"
        >
          <slot name="right-column" />
        </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    maxWidth: {
      type: String,
      default: '1250px',
    },
    leftColumn: {
      type: Number,
      default: 7,
    },
  },
  computed: {
    rightColumn() {
      return 12 - (this?.leftColumn || 0)
    },
  },
}
</script>

<style></style>
