<template>
  <v-container
    v-if="reservation && reservation.reservationId"
    v-resize="onResize"
    style="padding: 0"
  >
    <v-icon
      style="margin-left: 30px"
      @click="
        $router.push({
          name: 'reservations',
        })
      "
    >
      arrow_back
    </v-icon>
    <TwoColumnLayout>
      <template #left-column>
        <v-layout
          v-if="$cr.breakpoint.lgAndUp"
          row
          style="padding: 0; margin-bottom: 12px; margin-left: 0"
        >
          <h2>
            {{ reservation | tripHeader }}
          </h2>
          <span
            v-if="reservation.quote"
            style="margin-left: 5px; font-size: 24px"
          >
            {{ `(#${reservation.managedId})` }}
          </span>
        </v-layout>
        <ReservationCard
          :img-width="$cr.breakpoint.smAndUp ? 220 : windowWidth"
          :reservation="reservation"
          :stacked="!$cr.breakpoint.smAndUp"
          :style="$cr.breakpoint.xs ? 'width: 100%; position: relative;' : ''"
          @click-support="
            $store.dispatch('openSupportDialog', reservation.reservationId)
          "
        />
        <article>
          <h3>Payment Overview Test</h3>
          <p>
            <v-data-table
              :headers="payment.headers"
              :items="reservation.transactions"
              hide-headers
              hide-actions
              class="elevation-0 payment-table"
            >
              <template #items="{ item }">
                <tr class="last-row">
                  <td style="font-size: 16px">
                    {{ item.notes ? item.notes : 'Null' }}
                  </td>
                  <td style="font-size: 16px" align="right">
                    {{ item.amount | currencyFilter }}
                  </td>
                </tr>
              </template>
            </v-data-table>
            <a
              id="reservation-detail-link-view-invoice"
              @click="generateInvoiceAndOpenNewWindow"
            >
              View Invoice
            </a>
          </p>
        </article>

        <article v-if="contact">
          <h3>Contact Information</h3>
          <p>
            <v-layout row wrap>
              <v-flex xs6>
                First Name
                <br />
                <b>{{ contact.firstName }}</b>
              </v-flex>
              <v-flex>
                Last Name
                <br />
                <b>{{ contact.lastName }}</b>
              </v-flex>
              <v-flex xs6 style="min-width: 300px">
                E-mail
                <br />
                <b v-if="!contact.email.includes('tripContact')">
                  {{ contact.email }}
                </b>
              </v-flex>
              <v-flex>
                Phone Number
                <br />
                <b>{{ contact.phoneNumber | phoneFormatFilter }}</b>
              </v-flex>
            </v-layout>
          </p>
        </article>

        <article>
          <h3>Help & Support</h3>
          <p>If it’s urgent, call us 24/7 at 1-855-920-2287.</p>
          <br />
          <a
            v-if="false"
            id="reservation-detail-link-contact-support"
            @click.stop="
              $store.dispatch('openSupportDialog', reservation.reservationId)
            "
          >
            Contact Support
          </a>
        </article>
      </template>
      <template #right-column>
        <TripCard
          v-if="reservation && reservation.trip"
          :style="$cr.breakpoint.lgAndUp ? 'margin-top:46px' : ''"
          :map="$cr.breakpoint.lgAndUp"
          itinerary
          customer-notes
          :trip="reservation.trip"
          :created-on="reservation.quote.createdOn"
        />
      </template>
    </TwoColumnLayout>
  </v-container>
  <v-container v-else-if="notFound" class="fill-height">
    <NotFound />
  </v-container>
</template>

<script>
import { DateTime } from 'luxon'
import TripCard from '@/components/charterup/TripCard.vue'
import { baseUrl } from '@/utils/env'
import ReservationCard from '@/components/charterup/ReservationCard.vue'
import { reservationById } from '@/services/charterup/reservations'
import { currencyFilter } from '@/utils/string'
import { phoneFormatFilter } from '@/utils/phone'
import NotFound from '@/components/charterup/NotFound.vue'

import TwoColumnLayout from '@/layouts/TwoColumnLayout.vue'

export default {
  components: { TripCard, ReservationCard, NotFound, TwoColumnLayout },
  filters: {
    tripHeader(reservation) {
      const trip = reservation.trip
      return `${DateTime.fromISO(trip?.stops[0]?.pickupDatetime).toLocaleString(
        DateTime.DATE_SHORT
      )} • ${trip?.stops[0]?.address.city} > ${trip?.stops[1]?.address.city}`
    },
    currencyFilter,
    phoneFormatFilter,
  },
  data() {
    return {
      windowWidth: 0,
      hash: null,
      payment: {
        headers: [
          { text: 'Date', value: 'date', align: 'center' },
          { text: 'Description', value: 'paymentType.label', align: 'left' },
          { text: 'Amount', value: 'amount', align: 'right' },
        ],
        history: [],
      },
      reservationData: null,
      quotes: null,
      notFound: false,
    }
  },
  computed: {
    contact() {
      return this.reservation?.customer
    },
    reservation() {
      return this.reservationData
    },
  },
  async mounted() {
    this.onResize()
    const { token } = this.$store.getters
    const { data } = await reservationById(this.$route.params.id, token)
    this.hash = data.hash
    if (data.reservationId === null) {
      this.notFound = true
    }
    this.reservationData = this.reservationDTO(data)
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth - 48
    },
    generateInvoiceAndOpenNewWindow() {
      window.open(
        `https://${baseUrl(
          'pdf'
        )}/pdf/charterup-invoice/reservations?reservationHashes=${encodeURIComponent(
          [this.hash].join('')
        )}`
      )
    },
    reservationDTO(flatReservations) {
      const nestedReservations = {}
      nestedReservations.reservationId = flatReservations.reservationId
      nestedReservations.quote = {
        quoteId: flatReservations.quoteId,
        createdOn: flatReservations.quoteCreatedOn,
        createdBy: flatReservations.quoteCreatedBy,
      }

      nestedReservations.leadSource = flatReservations.leadSource
      nestedReservations.assignedDrivers = flatReservations.assignedDrivers

      nestedReservations.trip = {
        tripId: flatReservations.tripId,
        stops: flatReservations.stops,
        description: flatReservations.customerNotes,
        vehicles: flatReservations.requiredVehicles,
        passengerCount: flatReservations.passengerCount,
        tripType: {
          label: flatReservations.tripType,
        },
      }

      nestedReservations.transactions = [
        {
          notes: 'Grand Total',
          amount: flatReservations.amount,
        },
      ]

      const autoPayOn = flatReservations.dueDate
        ? `(auto-pay on ${DateTime.fromISO(
            flatReservations.dueDate
          ).toLocaleString(DateTime.DATE_SHORT)})`
        : ''
      nestedReservations.transactions.push({
        notes: `Remaining Balance ${autoPayOn}`,
        amount: flatReservations.balance,
      })

      nestedReservations.company = {
        companyDBAName: flatReservations.companyDBAName,
        rating: flatReservations.referredTo?.[0]
          ? flatReservations.referredTo[0].companyRating
          : flatReservations.companyRating,
        reviewCount: flatReservations.referredTo?.[0]
          ? flatReservations.referredTo[0].companyReviewCount
          : flatReservations.companyReviewCount,
        amenityDTO: flatReservations.referredTo?.[0]
          ? flatReservations.referredTo[0].companyAmenityDTO.filter(
              (amenity) => amenity.isSupported
            )
          : flatReservations.companyAmenityDTO.filter(
              (amenity) => amenity.isSupported
            ),
        photos: flatReservations.vehiclePhotoCharterUpDTO,
      }

      nestedReservations.customer = {
        firstName: flatReservations.customerFirstName,
        lastName: flatReservations.customerLastName,
        phoneNumber: flatReservations.customerPhoneNumber,
        email: flatReservations.customerEmail,
      }

      return nestedReservations
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/charterup/design';

.payment-table {
  margin-top: 12px;
  margin-bottom: 24px;
}

.last-row {
  &:last-child {
    font-weight: normal;
  }
}

.mobile-trip-header {
  position: fixed;
  top: 3.5rem;
  left: 0;
  z-index: 10;
  width: 100vw;
  background-color: $gray-blue;
}
</style>
