<template>
  <v-dialog
    v-model="value"
    :width="driverState === 'assigned' ? 600 : 500"
    :content-class="$cr.breakpoint.xs ? 'fullscreen-dialog' : ''"
    :fullscreen="$cr.breakpoint.xs"
    @click:outside.stop="$emit('input', false)"
    @keydown.stop="
      (event) => {
        if (event.keyCode === 27) {
          $emit('input', false)
        }
      }
    "
  >
    <v-card>
      <v-card-title class="primary">
        <v-layout row dense>
          <v-flex>
            <h3 class="modal-title">Driver Info</h3>
          </v-flex>

          <v-flex class="shrink">
            <a
              id="call-driver-dialog-button-close"
              @click="$emit('input', false)"
            >
              <v-icon color="white">close</v-icon>
            </a>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-flex style="text-align: center">
            <template v-if="driverState === 'no-number'">
              <img src="@/assets/images/icon-quote-expired.png" width="100" />
              <h2>Driver info not available yet.</h2>
              <p>
                For the safety and security of our clients, driver information
                is only available a few hours before your trip. If you need help
                now, click
                <a
                  id="call-driver-dialog-button-contact-support"
                  @click="
                    $emit('click-support')
                    $emit('input', false)
                  "
                >
                  Contact Support
                </a>
                .
              </p>
              <br />
              <v-btn
                id="call-driver-dialog-button-exit"
                dark
                primary
                small
                class="primary"
                style="width: 50%"
                @click="$emit('input', false)"
              >
                Back to site
              </v-btn>
            </template>

            <template v-else-if="driverState === 'unassigned'">
              <img src="@/assets/images/icon-quote-expired.png" width="100" />
              <h2>No phone numbers listed.</h2>
              <p>
                We're unable to find this driver&rsquo;s number.
                <br />
                If it&rsquo;s urgent, call us 24/7 at 1-855-920-2287.
              </p>
              <v-btn
                id="call-driver-dialog-button-back-to-site"
                dark
                primary
                small
                class="primary"
                style="width: 50%"
                @click="$emit('input', false)"
              >
                Back to site
              </v-btn>
            </template>

            <template v-else>
              <v-layout
                v-for="(driver, i) in assignedDrivers"
                :key="`call-driver-dialog-id-${driver.driverId}`"
                row
              >
                <v-spacer cols="1" />
                <v-flex cols="5" class="text-right">
                  {{ driver.firstName }}
                </v-flex>
                <v-flex cols="5" class="text-left">
                  <span>
                    {{ phoneFormatFilter(driver.phone) }}
                    <a
                      :id="`call-driver-dialog-link-${i}`"
                      :tel="`${driver.phone}`"
                      :href="`tel:${driver.phone}`"
                    >
                      <v-icon
                        color="primary"
                        style="margin-left: 0.5em; font-size: 18px"
                      >
                        phone
                      </v-icon>
                    </a>
                  </span>
                </v-flex>
                <v-spacer cols="1" />
              </v-layout>
            </template>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { phoneFormatFilter } from '@/utils/phone'
export default {
  props: {
    value: {
      type: Boolean,
    },
    assignedDrivers: {
      type: Array,
      default: null,
    },
  },
  computed: {
    driverState() {
      if (!this.assignedDrivers) {
        return 'no-number'
      }
      if (!this.assignedDrivers.length) {
        return 'unassigned'
      }
      return 'assigned'
    },
  },
  methods: {
    phoneFormatFilter,
  },
}
</script>

<style lang="scss">
.v-dialog {
  border-radius: 10px;
}
.modal-title {
  color: $white;
  font-weight: 700;
}
</style>
