import { render, staticRenderFns } from "./ReservationCard.vue?vue&type=template&id=3181017e&scoped=true"
import script from "./ReservationCard.vue?vue&type=script&lang=js"
export * from "./ReservationCard.vue?vue&type=script&lang=js"
import style0 from "./ReservationCard.vue?vue&type=style&index=0&id=3181017e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3181017e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRating } from 'vuetify/lib/components/VRating';
installComponents(component, {VBtn,VCard,VChip,VFlex,VIcon,VImg,VLayout,VRating})
